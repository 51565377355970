.dashboard-wrapper {
  position: relative;
  height: 100%;
  .dashboard-intro-section {
    padding: 40px 50px;
    position: relative;
    &:after {
      @include absoluteCover();
      background: linear-gradient(to bottom, #d4e3fc, #ffffff);
      z-index: 3;
      border-bottom-left-radius: 24px;
    }

    & > * {
      position: relative;
      z-index: 5;
    }

    .row--intro-header {
      text-align: center;
      margin-bottom: 40px;

      h2 {
        font-size: 3.2rem;
      }

      p {
        color: $text-secondary;
      }
    }

    .row--intro-content {
      .intro-content {
        h3 {
          margin-bottom: 10px;
          font-weight: 600;
        }

        ul {
          margin-bottom: 25px;
          li {
            padding-block: 5px;
            img {
              margin-right: 10px;
            }
          }
        }
      }

      .intro-img {
        margin-top: -30px;
      }
    }
  }
}

.dashboard-cardlist-wrapper {
  position: relative;
  z-index: 5;
  padding: 0 40px 50px;
  h3 {
    text-align: center;
    font-size: 2rem;
  }

  .row--dashboard-cardlist {
    margin-top: 40px;
  }
}

.dashboard-link-card {
  max-width: 300px;
  margin-inline: auto;
  text-align: center;
  position: relative;
  padding: 20px;
  & > a {
    @include absoluteCover;
    z-index: 5;
  }

  &:after {
    @include absoluteCover;
    z-index: 3;
    border-radius: 12px;
  }

  &:hover {
    &:after {
      background: rgba($primary, 0.08);
    }
  }

  .card-img {
    text-align: center;
  }

  .card-body {
    h3 {
      font-size: 1.6rem;
      font-weight: 600;
      margin: 15px 0 5px;
    }

    p {
      font-size: 1.4rem;
      color: #767676;
      font-weight: 300;
    }
  }
}

.content{
  padding:20px;
}