.company-information {
  max-width: 1100px;
  border: 1px solid #dbdbdb;
  border-top: 0;
  border-radius: 8px;
  margin-bottom: 40px;
  margin-inline: auto;
  // box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  //   margin-inline: auto;
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 7px 15px;
    background: $primary-light-background;
    align-items: center;
    h3 {
      font-size: 24px;
      font-weight: 500;
    }
  }

  .switch-form{
    .ant-form-item{
      margin-bottom:0px;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    .company-info-list {
      li {
        padding-block: 7px;
        strong {
          margin-right: 5px;
        }

        span {
          color: #787878;
          display: block;
        }
      }
    }

    .company-logo {

      .avatar{
        height: 200px !important;
        width: 200px !important;
        border: 1.5px solid #dbdbdb;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 0px;
        svg{
          font-size: 90px;
        }

      }
      // img {
      //   max-width: 100%;
      //   height: auto;
      // }
    }

    .company-logo-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      .change-logo{
        margin-top: 10px;
      }
   }
  }
  .select{
    min-width:150px;
  }
}

.preview-template{
  .select{
    margin-bottom:0px;
  }
}

.form--onboarding.form--onboarding--settings {
  max-width: 900px;
  margin-inline: auto;
  background: $primary-light-background;
  padding: 30px;

  .form-submit-btn {
    width: 100%;
    text-align: center;
    margin: 20px 0 0;
  }
}
