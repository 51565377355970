.status-overview-wrapper,.tds-overview-wrapper {
  // background: $primary-light-background;
  padding: 15px 20px 5px 20px;
  margin-bottom: 30px;
  margin-inline: -20px;
    display: flex;
    justify-content: space-between;

    &__col{
      display: flex !important;
      .status-info {
        width: 100%;

        h4 {
          font-weight: 500;
          font-size: 1.4rem;
          color: #505050;
        }
        h2 {
          sub {
            margin-left: 5px;
            font-size: 1.6rem;
          }
        }
        .updated-time {
          font-size: 1.2rem;
          color: #909090;
    
          .anticon {
            font-size: 18px;
            cursor: pointer;
            color: $primary;
            margin: 5px;
          }
        }
    
        .deadline {
          color: #cc3300;
        }
    
        .ant-form-item {
          margin-bottom: 0;
          .ant-form-item-label {
            font-size: 16px;
          }
        }

     
        &__monthlyFilters{
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          &__month{
            display: inherit;
            .month-form{
              min-width:140px;
              margin-left:10px;
            }
          }
        }
      }
    }

    .tds-receivable-container,
  .tds-payable-container,
  .year-filter {
    padding: 20px;
    h4,
    label {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  .tds-payable-container {
    background-color: #fdf5f5;
    margin-right:15px;
    flex-grow: 1;
    h4 {
      color: $red;
    }
  }

  .tds-receivable-container {
    background-color: #f3f9f2;
    flex-grow: 1;
    margin-right:15px;
    h4 {
      color: $green;
    }
  }

  .year-filter {
    background-color: #f6f8ff;
    flex-grow:1;
    flex-shrink: 0;
   
  }
   
}
.tds-overview-wrapper{
  display:inline
}

