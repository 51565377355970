.ant-popover {
  min-width: 150px;
  &.ant-popconfirm {
    .ant-popover-buttons {
      .ant-btn.ant-btn-sm {
        display: inline-flex;
        padding: 0px 14px;
        justify-content: center;
        align-items: center;
        height: 30px;
        font-size: 14px;
      }
    }
  }

  .popover-export-file {
    .export-container {
      .ant-select {
        width: 100%;
      }
    }
  }

  .ant-btn.ant-btn-sm {
    height: 30px;
  }
}
