.ant-layout {
  .ant-layout-sider {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
      //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      //   background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #eff2fc;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d8dce6;
      //   border: 2px solid #555555;
    }
    .ant-layout-sider-children {
      height: auto;
    }
  }
}
