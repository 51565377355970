.ant-modal-root {
  .ant-modal {
    .ant-modal-header {
    }

    .ant-modal-body {
      .app-page-body {
        padding: 0;

        .ant-form {
          padding-top: 0;
          background: $primary-light-background;
        }
      }

      .create-section__form {
        margin-bottom: 0;
        padding: 20px 10px;
      }
    }

    .btn-modal-submit {
      margin-top: 10px;
      margin-inline: auto;
      display: inherit;
    }

    &.customer-modal {
      .ant-modal-body {
      }
    }
  }

  .ant-btn-dashed.ant-btn-dashed--add-customer {
    border-color: $primary;
    margin-top: 20px;
    text-align: right;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.preview-modal{
  .ant-modal-body{
   display: flex;
   align-items: center;
   justify-content: center;
  }
}

  
  
