.maintance-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100vh;

  img{
    height:300px;
  }

  h2{
    margin-top: 20px;
    margin-bottom:10px;
    letter-spacing: 1.2px;
    color:#3C3F44;
  }

  span{
    line-height: 18px;
    color:#6F6E71;
    letter-spacing: 1.1px;
  }
  
  .btns{
    margin-top:15px;
    .refresh-btn{
      margin-right:10px;
      span{
        color:$primary ;
        text-decoration: underline;
      }
    }
    .logout-btn{
    
    }
  }
}