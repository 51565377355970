.ant-tabs {
  .ant-tabs-nav {
    &::before {
      border-bottom: 2px solid #efefef !important;
    }
    .ant-tabs-nav-list {
      .ant-tabs-tab-btn {
        font-weight: 600;
      }
      .ant-tabs-ink-bar {
        height: 2.5px;
      }
    }
  }
}
