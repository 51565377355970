.print-template {
  margin: 20px;
  padding: 10px !important;
  align-self: center;
  box-shadow: 6px 6px 42px rgba(0, 0, 0, 0.08);
  // width:803.7px !important;
  // height: 1132px !important;
  .estimate-detail-wrapper {
    width: 793.7px !important;
    height: 1122px;
    padding: 0 !important;

    .billing-info {
      padding: 5px 10px !important;
      border-radius: 12px;
      line-height: 1.3;
      font-size: 12px;
    }
    .estimate-detail-header {
      font-size: 10px !important;
      .recipient-company-info {
        h2 {
          font-size: 15px;
        }
      }
    }

    .estimate-detail-item {
      font-size: 14px;
      h4,
      p {
        line-height: 1.2 !important;
      }
      h4 {
        font-size: 12px !important;
        width: 332px !important;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
      }
      p {
        width: 332px !important;
        font-size: 11px !important;
        color: #898989;
        // display: none !important;
      }
    }
  }
  // .estimate-total-amount {
  //   margin-top: 10px !important;
  //   .amount-words {
  //     p {
  //       line-height: 1.2;
  //       font-size: 12px;
  //     }
  //   }

  //   .total-amount {
  //     span {
  //       font-size: 13px;
  //     }
  //   }
  // }
  .alt-pdf-header {
    .invoice-label-type {
      margin-bottom: 0 !important;
    }
    .company-info {
      .recipient-company-info {
        h3 {
          font-size: 13px;
        }
        p {
          line-height: 1.2;
          font-size: 11px;
        }
      }
    }

    .vat-no {
      margin-top: 0 !important;
      font-size: 11px;
      font-weight: semibold;
    }

    .estimate-time-info {
      p {
        font-size: 11px;
        line-height: 1.2;
      }
    }
  }

  .estimate-info--alternate {
    &.estimate-info {
      padding-bottom: 10px !important;
    }
    .billing-info {
      padding: 5px 10px !important;
      border-radius: 12px;
      line-height: 1.3;
      font-size: 12px;
      border: 3px solid #8292a7 !important;
    }
  }

  .estimate-detail {
    .estimate-detail-header {
      margin-bottom: 0 !important;
      border-top: 1px solid #232323;
      border-bottom: 1px solid #232323;

      .recipient-company-info {
        h4 {
          font-size: 10px;
        }
      }

      h3 {
        font-size: 12px !important;
      }
    }
    .body-wrapper {
      min-height: 50px !important;
      padding-top: 3px;
    }
    .estimate-detail-body {
      padding-block: 3px !important ;
      border-bottom: 1px solid #a8a8a8;

      .estimate-detail-item {
        font-size: 14px;
        h4,
        p {
          line-height: 1.2 !important;
          // text-overflow: ellipsis;
          // overflow: hidden;
          // white-space: nowrap;
        }
        h4 {
          font-size: 12px !important;
          width: 332px !important;
          margin-bottom: 0 !important;
          padding-bottom: 0 !important;
        }
        p {
          width: 332px !important;
          font-size: 11px !important;
          color: #898989;
          // display: none !important;
        }
      }

      .ant-col {
        p {
          font-size: 11px !important;
        }
      }
    }
  }
}

.preview-page {
  display: flex;
  flex-direction: column;
  // align-items: center;
  &__header {
  }

  .print-template {
    padding: 0px !important;
  }
}

.template-80mm {
  // width: 80px !important;
  max-width: 80mm !important;
  height: auto !important;
  // height: 500px !important;

  .header-80mm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px !important;
    &__company {
      text-align: center;
      line-height: 18px;

      h1 {
        font-weight: normal;
        line-height: 18px;
        font-size: 16px;
      }
      h4 {
        font-weight: normal;
      }
    }
    &__label {
      text-align: center;
      text-transform: uppercase;
    }
    h1,
    h3,
    h4,
    p {
      color: #000 !important;
    }
  }

  .second-row-80mm {
    .info-row {
      display: flex;
      flex-direction: row;
      font-size: 12px;
      font-weight: normal;
      &__title {
        min-width: 55px;
      }
      &__value {
        margin-left: 2px;
      }
    }
  }

  .preview-footer-80mm {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding-top: 7px;
  }

  .billing-info {
    border-bottom: 2px dashed $black !important;
  }

  .template-header {
    border: 0px !important;
    text-transform: uppercase;
  }

  .table-head {
    border-block: 1.5px dashed $black !important;
    margin-block: 7px !important;
    &__price {
      text-align: right;
    }

    h3 {
      color: #000 !important;
    }
  }

  .estimate-detail {
    padding: 0px;
    display: flex;
    flex-direction: column;
    //   min-height: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    // border: 1px solid;
  }

  .estimate-detail-body {
    border-bottom: 0px !important;

    .item-title {
      overflow: hidden;
      margin-right: 8px;
      font-size: 12px !important;
      word-break: break-word;
      color: #000 !important;
    }

    .estimate-detail-quantity {
      word-break: break-word;
    }

    .estimate-detail-price {
      text-align: right;
      word-break: break-word;
    }

    .estimate-detail-amount {
      word-break: break-word;
    }
  }

  .estimate-detail-item {
    h4 {
      font-weight: normal !important;
      color: #000;
    }
  }
}

.bill-view-bottom-section {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // width:100%;
  // max-height:310px;
  .estimate-note {
    padding-top: 2px;

    h3 {
      margin-bottom: 0 !important;
      font-size: 11px;
    }
    p {
      font-size: 11px;
    }
  }

  .preview-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 20px;
    .estimate-footer {
      padding: 40px 0 10px;
      text-align: center;
      .estimate-signature {
        span {
          font-size: 12px;
          font-weight: bold;
        }

        .recipient-title {
          font-size: 12px;
        }
      }
    }

    .printed-info-footer {
      margin-top: 0 !important;

      padding: 10px;
      margin-top: 20px;
      border-top: 0.6px solid #717171;
      span {
        font-size: 11px;
      }
    }
  }
  .preview-footer-80mm {
    border-top: 1.5px dashed $black !important;
  }

  .table-calculation {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
    align-self: flex-end;
    min-width: 200px;
    color: $black !important;

    .row-calculation {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-block: 0px !important;
      line-height: 1.3 !important;
      p {
        font-size: 12px;
      }

      strong {
        font-size: 12px;
      }

      &__value {
        min-width: 100px;
        text-align: right;
      }
      &__title {
        margin-top: 3px;
        margin-right: 7px;
      }
    }
  }

  .estimate-total-amount {
    // margin-top: 20px;
    border-top: 0.5px solid #7d797e;
    border-bottom: 0.5px solid #7d797e;
    align-items: center;
    padding-block: 5px;

    .total-amount {
      font-weight: 700;
      font-size: 13px;
      text-align: right;
      span:first-child {
        margin-right: 10px;
      }
    }
  }

  .amount-words-wrapper {
    border-block: 1.5px dashed $black !important;
    padding-block: 8px;
    font-size: 13px;
    line-height: 17px;
    text-transform: capitalize;
  }

  .row-estimate-note {
    padding-bottom: 5px !important;
    h3 {
      font-weight: normal !important;
      font-size: 13px;
    }
    p {
      color: $text-primary;
      font-size: 13px;
    }
  }
}

.border-top {
  border-top: 1.5px dashed $black !important;
}
