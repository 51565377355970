.create-section {
  & > h3 {
    // margin-bottom: 10px;
    font-size: 2.4rem;
    font-weight: 500;
    position: sticky;
    top: 30px;
  }

  &__form {
    max-width: 850px;
    margin-inline: auto;
    margin-bottom: 40px;
  }

  .form-title {
    text-align: right;
    color: rgb(187, 187, 187);
    font-weight: 400;
    font-size: 1.8rem;
  }
}

.ant-form.form--dash {
  padding: 10px 20px;

  .ant-form-item {
    margin-bottom: 10px;
    label {
      font-weight: 600;
    }
  }

  .create-section__form {
    .ant-col-18 {
      background: #f5f8fd;
      padding-block: 15px;
      border-radius: 12px;
    }
  }
  span.input-subtext {
    font-size: 1.3rem;
    color: #909090;
    font-weight: 300;
    margin-top: 5px;
    line-height: 1.4;
  }

  .ant-form-item-explain {
    font-size: 1.2rem;
    margin-top: 7px;
  }
}

.ant-input::placeholder {
  font-size: 1.4rem;
  letter-spacing: 0.5px;
}

.ant-form {
  .ant-form-item-label {
    label {
      font-weight: 600;
    }
  }
}

.ant-input-number {
  width: 100% !important;
}
