.customer-statement {
  .search-container {
    .search-row {
      display: flex;
      align-items: center;
      background-color: #f1f7ff;
      padding: 10px 15px;

      .ant-col {
        // margin:5px;
      }
    }
  }

  .initial-component {
    //  padding-top:40px;

    .initial-info {
      padding-top: 55px;

      img {
        width: 200px;
      }
    }
  }
  .generating{
    text-align: center;
  }

  &__preview{
    width:793.7px;
    // height: 1122px !important;
    box-shadow: 6px 6px 42px rgba(0, 0, 0, 0.08);
    margin:auto;
    .estimate-detail-wrapper{
      padding:0px !important;
    }
  }

  .download-container{
    width:793.7px;
    margin:auto;
    margin-bottom:10px;
  }
}
.statement-form--customer-statements {
  display: flex;
  justify-content: center;
  .search-row--customer-statements {
    max-width: 1000px;
    width: 100%;

    .ant-picker {
      display: flex;
    }
  }
}
