.stats-card{
  padding:20px 25px;
  background-color: #F6F8FF;
  margin:0 20px 20px 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__header{
    display: flex;
    align-items: center;
    h5{
      margin-left:10px;
      color:$text-secondary
    }
  }

  &__spinner{
    margin-top:12px;
  }

  sub{
    color:$green
  }
}