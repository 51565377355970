  .create-form {
    padding-block: 15px;
    max-width: 1100px;
    margin:80px 0px;
    margin-inline: auto;
    box-shadow: 6px 6px 42px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    

  .expense-table-section {
    padding: 7px 20px;


    .estimate-table-header {
     margin:0 !important
    }

    .add-item-btn {
      color: $primary;
      padding: 20px;
      span {
        cursor: pointer;
      }
    }

    .ant-col {
      .ant-form-item {
        .ant-form-item-control-input {
          .ant-input-number {
            width: 100%;
          }
        }
      }
    }

    .btn-add-estimate-item {
      margin-top: 20px;
      color: $primary;
    }
  }

  .information-section {
    flex: 1;
    background-color: #fff;
    // padding: 20px;

      .company-header-wrapper{
        padding: 0px 20px;
        margin-bottom:20px;
        border-bottom: 1px solid rgb(241, 234, 234);
      }

      .estimate-detail-header{
        border: 0;
        margin:0;
      }

      .bill-to-wrapper{
        padding:0px 20px 20px 20px;
        margin-bottom:15px;
        border-bottom: 1px solid rgb(241, 234, 234);
        .added-customer-detail {
          // cursor: pointer;
         
        }

        .add-customer-card {
          display: flex;
          min-height: 140px;
          align-items: center;
          justify-content: center;
          border: 1px solid rgb(241, 234, 234);
          cursor: pointer;
          border-radius: 8px;

          .anticon {
            font-size: 64px;
            color: $primary;
          }

          h3 {
            color: $primary;
            font-size: 2.2rem;
            margin-left: 15px;
          }
        }
      }

      .estimate-section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .ant-form-item {
          .ant-form-item-control {
            width: 270px;
            .ant-input-number,
            .ant-picker {
              width: 100%;
            }
          }
        }
        
      }
  }

  .bottom-section {
    margin-top:20px;
    padding: 20px;
    flex: 1;
    border-top:1px solid rgb(241, 234, 234);

    .calculation-section {
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      p {
        display: inline-block;
        strong {
          text-align: left;
          min-width: 130px;
          display: inline-block;
        }
      }

      span {
        width: 150px;
        text-align: right;
        display: inline-block;
      }
    }

    .row--final-price {
      margin-top: 15px;
      .amount-words,
      .final-estimate-price {
        padding: 5px 15px;
        background: $primary-light-background;
        font-size: 1.8rem;
      }
      .amount-words{
       text-transform: capitalize;
      }

      .final-estimate-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        strong {
        }
        span {
          color: $primary;
          font-weight: 700;
          text-align: right;
        }
      }
    }
  }
}