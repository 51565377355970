.initial-info {
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  text-align: center;
  align-items: center;
  flex-direction: column;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, #d4e3fc, #ffffff);
    z-index: 3;
  }

  & > * {
    position: relative;
    z-index: 5;
  }

  h1 {
    font-size: 3.6rem;
    color: $primary;
  }

  img {
    margin-inline: auto;
    display: inline-block;
    margin-block: 30px;
    width:250px;
  }

  .ant-btn {
    margin-top: 20px;
  }

  p{
    width:50%;
    padding:0px 10px;
  }
}
