.submission-form {
  padding: 15px;
  .header {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    h3 {
      color: $text-secondary;

      font-weight: 500;
    }

    h2 {
      font-weight: 800;
      font-size: 36px;
      margin-block: 10px;
    }
  }

  .section {
    margin: 15px 0;

    .title {
      font-weight: 600;
      font-size: 18px;
      padding: 10px 15px;
      background: $primary-light-background;
      display: block;
      margin-bottom: 10px;
    }
  }

  .tax-description {
    width: 100%;

    .primary-form-bg {
      background-color: #f9edbe;
    }

    .item-header {
      padding: 25px 0;
    }

    .items-row {
      padding: 15px 10px;
    }
    .sub-items-row {
      padding: 0px 30px;
    }
  }

  .transaction-details {
    padding: 30px;
  }
  .preview-loading {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: #f9edbe;
    border-radius: 4px;
  }

  #create-customer.ant-form {
    padding-inline: 0;
    .title {
      color: #101010;
    }

    .ant-input-number-disabled,.ant-input-disabled {
      // background-color: rgb(255, 255, 255);
      color:#101010
    }

    .tax-description {
      padding: 0 15px 20px;
      .text-message {
        font-size: 15px;
        font-weight: 700;
        //   color: $text-secondary;
        background: #f7f7f7;
        padding: 12px 15px;
        color: #505050;
        width: 100%;
        text-align: center;
      }

      .primary-form {
        background-color: #f9edbe;
        margin-top: 20px;

        .button {
          font-size: 14px;
          margin: 0 15px;
          height: 36px;
          font-weight: 700;
        }

        .item-header {
          padding-inline: 20px;
          h4 {
            font-size: 16px;
          }
        }

        .item {
          padding-inline: 20px;
          .items-row {
            h3 {
              font-size: 18px;
            }
          }
          .sub-items-row {
            margin-bottom: 20px;
            position: relative;
            padding-bottom: 10px;
            &:after {
              position: absolute;
              content: "";
              bottom: 0;
              left: 40px;
              right: 40px;
              height: 1px;
              background: #ccc;
            }
            & > .ant-col {
              //   border-right: 1px solid #ccc;
              font-size: 14px;
              font-weight: 900;
            }
          }
        }

        .secondary-form-bg {
          background-color: #dfe9f6;
          padding-block: 20px;

          .items-row {
            h3 {
              font-size: 16px;
            }
          }
          .btn-payment {
          }
        }
      }
      .sub-item {
        padding-left: 25px;
      }
    }
  }

  .row-submited-by {
    background: #f0f0f0;
    padding: 10px 15px;
    h3 {
      margin-bottom: 7px;
      font-size: 18px;
    }
  }

  .row-submit-btn {
    justify-content: center;
    margin-top: 30px;
    .ant-btn {
      width: 240px;
      height: 50px;
      font-size: 20px;
    }
  }
}

.section-static-form {
  .ant-row {
    .ant-col {
      .reminder-text {
        display: grid;
        height: 100%;
        place-content: center;
        font-weight: 500;
        color: #919191;
        font-size: 14px;
      }
      .ant-form-item {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        .ant-form-item-label {
          text-align: left;
          font-weight: 700;
        }

        .ant-form-item-control {
          .ant-form-item-control-input {
            height: 45px;

            .ant-select-selector,
            .ant-form-item-control-input-content,
            .ant-input-number {
              height: 45px;
              .ant-select-selection-item,
              .ant-input-number-input-wrap,
              .ant-form-item-control-input-content {
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 45px;
                color: #505050;
              }

              .ant-input {
                height: 100%;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 45px;
                color: #505050;
              }
            }
          }
        }
      }
    }
  }
}

.ant-descriptions {
  &-view {
    .ant-descriptions-row {
      display: flex;
      flex-direction: column;

      .item-label {
        font-weight: 700;
        color: $text-secondary;
      }

      p {
        font-size: 17px;
      }
    }
  }
}
