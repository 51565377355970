.nepali-date-picker-wrapper {
  .nepali-date-picker {
    padding: 8px;
    border: 1px solid #dbdbdb;
    width: 100%;

    input {
      border-color: red !important;
    }
  }

  ._2xcMq {
    font-family: $font-primary;
    ._1Op28._3fNxl {
      background: $primary;
      ._3fmCl {
        // margin-top: 0;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 6px;
        margin-block: 9px;
      }

      ._2aNF9 {
        padding: 2px 10px;
        border-radius: 3px;
        margin-inline: 3px;
      }
    }

    ._d8Qqp {
      margin-block: 10px;

      ._1yGdK {
        font-size: 14px;
        font-weight: 700;
      }
    }

    ._1hh2I {
      ._3zttl {
        padding: 10px 18px;
      }

      ._249_g {
        padding: 8px 5px;
        flex-basis: 36px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        // color: #434343;

        &._2nANS {
          background-color: $primary;
        }
      }
    }
  }
}
