.products-page{
  .data-list-table{
    .actions{
      display: flex;
      justify-content: center;
      &__button{
        &:first-child{
          margin-right:20px;
        }
        svg{
          font-size: 20px;
          // margin-right:15px;
          margin-top:5px;
          cursor: pointer;
        }
      }
      
    } 
  }
}