@import "./sidenav";
@import "./header";
@import "./page-header";
@import "./dashboard-sidebar";
@import "./print";

#app-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  background: #fff;
  & > .ant-row {
    width: 100%;
  }
  .ant-layout-sider {
    // height: 100vh;
    position: fixed;
    left: 0;
    z-index: 999;
    background: #eff2fc;
    // height: 100%;

    .ant-menu,
    .sidebar__secondary-menu {
      background-color: transparent !important;
    }
    .ant-layout-sider-children {
      // height: 100%;

      .ant-menu {
        // height: auto !important;
        // padding-top: 10px;
        & > .ant-menu-item,
        & > .ant-menu-submenu {
          font-size: 1.5rem;
          margin-bottom: 10px;
          .anticon {
            font-size: 20px;
            width: 35px;
            text-align: left;
            margin-right: 5px;
          }
        }

        & > .ant-menu-item {
          a {
            font-weight: 500;
          }
        }

        .ant-menu-item {
          overflow: unset;
          height: auto;
          padding-right: 0;
          .ant-menu-title-content {
            overflow: unset;
          }

          a {
            position: relative;
            display: flex;
            line-height: 1.5;
            vertical-align: middle;
            width: 100%;
            align-items: center;
            padding-block: 10px;
            .ant-badge {
              position: absolute;
              height: 20px;
              font-size: 11px;
              font-weight: 500;
              left: 30px;
              top: 0px;
              background: none;
              padding: 0;
              letter-spacing: 1px;
              &.coming-soon {
                color: #0bb900;
              }

              &.beta {
                color: #007bff;
              }
            }
          }
          &.ant-menu-item-selected {
            background-color: rgba(187, 223, 255, 0.27);
          }
        }

        .ant-menu-submenu {
          .ant-menu-title-content {
            margin-left: 0;
            font-weight: 600;
          }
          .ant-menu {
            padding-top: 0;
            &-item {
              font-size: 1.5rem;
              min-height: 35px;

              a {
                font-weight: 400;
                color: darken ($text-secondary, 5%);

                .ant-badge {
                  left: 0;
                }
              }
            }
          }
        }
      }

      .sidebar__secondary-menu {
        font-size: 1.4rem;
        padding-top: 10px;

        .logout-btn {
          display: flex;
          align-self: baseline;
          margin: 5px 0 0 16px;
        }
      }
    }
  }
}

.main-app-layout {
  background-color: #ffffff !important;
  // padding: 40px;
  // width: calc(100% - 200px);
  // height:100%,
  // margin-left: 200px;
}

.main-content {
  padding: 0px;
}

.app-page-body {
  padding: 30px;
}
