.spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #8f44fd);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 5px), #000 0);
  animation: spin 1.5s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
