.estimate-detail-wrapper {
  // max-width: 1000px;
  margin-inline: auto;
  // box-shadow: 6px 6px 42px rgba(0, 0, 0, 0.08);
  padding: 20px !important;
  border-radius: 10px;
  position: relative;
  padding-bottom: 200px;
  .estimate-info {
    // padding: 0px 0 40px;
    padding: 5px 0;
    border-top: 1px solid #101010;
    .billing-info {
      text-transform: capitalize;
      // border: 3px solid #dee6f0;
      // padding: 10px 15px;
      border-radius: 12px;
      h3 {
        font-weight: 500;
      }
      p {
        line-height: 1.3;
        font-size: 1.3rem;
      }
      .pan-no {
        font-size: 15px;
      }

      span {
        letter-spacing: 1px;
        font-weight: 700;
      }
    }

    .estimate-info--alternate {
      padding: 0px 0 40px;
      border-top: 0;
      .billing-info {
        text-transform: capitalize;
        border: 3px solid #dee6f0;
        padding: 10px 15px;
        border-radius: 12px;
      }
    }
  }
  .estimate-detail {
    padding-inline: 5px;
    // min-height: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    // border: 1px solid;
    .estimate-detail-header {
      padding: 5px 0;
      // background: $primary-light-background;
      border-top: 1px solid #909090;
      border-bottom: 1px solid #909090;

      h3 {
        font-size: 1.4rem;
      }

      .ant-col {
        &:last-child {
          h3 {
            text-align: right;
          }
        }
      }
    }

    &-body {
      padding: 7px 0;
      font-size: 1.4rem;
      border-bottom: 1px solid #dbdbdb;

      .estimate-detail-item {
        font-size: 1.5rem;

        h4 {
          width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 500;
          margin-bottom: 0;
        }

        .description {
          width: 400px;
          font-size: 1.3rem;
          line-height: 1;
          color: #767676;
        }
        .limited-description {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        ul {
          padding-left: 15px;
          margin-top: 5px;
          li {
            line-height: 1.5;
          }
        }
      }

      .estimate-detail-amount {
        text-align: right;
      }
    }

    .row-customer-statement--balance {
      p {
        font-weight: 600;
      }
      &.row-customer-statement--balance {
        background: #b2c2cd;

        p {
          font-weight: 700;
        }
      }

      .ant-col {
        &:last-child {
          p {
            text-align: right;
          }
        }
      }
    }

    .customer-statement-closing-balance {
      margin-bottom: 20px;
      p {
        background: $primary-light-background;
        padding: 5px 15px;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;

        strong {
          color: $red;
        }
      }
    }
  }

  .estimate-bill {
    padding: 3px 0;
    font-size: 1.5rem;
  }

  .uploaded-bills {
    margin-bottom: 30px;
  }

  .uploaded-bill-img {
  }

  // .estimate-footer {
  //   padding: 50px 0 10px;
  //   text-align: center;
  // }
  .gen-inv-section {
    padding: 40px;
  }
  // .printed-info-footer {
  //   padding: 10px;
  //   margin-top: 20px;
  //   border-top: 0.6px solid #717171;
  //   font-size: 1.3rem;
  // }
}

.recipient-title {
  font-weight: 600;
}

.estimate-detail-download-container {
  max-width: 1000px;
  margin-inline: auto;
  margin-bottom: 10px;
}

.estimate-detail-header {
  // border-bottom: 1px solid #ccc;
  margin-bottom: 5px;

  .company-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    img {
      display: inline-block;
      max-height: 84px;
      width: auto;
    }
    span,
    strong {
      font-size: 1.4rem;
    }
    h4 {
      margin-top: 10px;
    }
  }

  .recipient-company-info {
    text-transform: capitalize;

    h2,
    h3 {
      text-align: right;
    }

    h4 {
      text-align: right;
      font-size: 12px !important;
    }
    p {
      font-size: 1.4rem;
      text-align: right;
    }
    .email {
      text-transform: none;
    }
  }

  .invoice-label-type {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.8rem;
  }

  .vat-no {
    font-size: 1.4rem;
  }
}

.estimate-time-info {
  text-align: right;
  p {
    font-weight: 500;
    font-size: 14px;
    padding-block: 1px;

    strong {
    }

    span {
      min-width: 100px;
      display: inline-block;
    }
  }

  .account-activity {
    margin-bottom: 10px;

    p {
      border-bottom: 1px solid #ccc;
      display: inline-block;
      padding-bottom: 10px;
    }
  }

  .closing-balance {
    margin-top: 5px;
    padding: 3px 10px 5px;
    background: $primary-light-background;
    display: inline-block;
  }
}

.alt-pdf-header {
  .estimate-time-info {
    &.estimate-time-info--alt-pdf {
      text-align: right;
    }
  }

  .recipient-company-info {
    h2,
    h3 {
      text-align: left;
    }

    p {
      font-size: 1.4rem;
      text-align: left;
    }
  }
}

.estimate-info.estimate-info--alternate {
  padding: 0px 0 40px;
  border-top: 0;

  .billing-info {
    text-transform: capitalize;
    border: 3px solid #dee6f0;
    padding: 10px 15px;
    border-radius: 12px;
  }
}

.preview-img {
  position: absolute;
  width: 100%;
  opacity: 0.7;
}

.bottom-section {
  // .row-calculation {
  //   padding-block: 5px;
  //   // background-color: red;
  //   display: flex;
  //   .ant-col {
  //     text-align: right;

  //     strong {
  //       font-size: 1.5rem;
  //       font-weight: 600;
  //     }
  //   }
  // }
  // .estimate-subtotal {
  //   margin-top: 10px;
  // }

  // .estimate-total-amount {
  //   margin-top: 20px;
  //   border-top: 0.5px solid #7d797e;
  //   border-bottom: 0.5px solid #7d797e;
  //   align-items: center;
  //   padding-block: 5px;
  //   .amount-words,
  //   .amount-words {
  //     text-transform: capitalize;
  //   }

  //   .total-amount {
  //     font-weight: 700;
  //     font-size: 1.7rem;
  //     text-align: right;

  //     span:first-child {
  //       margin-right: 10px;
  //     }
  //   }
  // }
  // .preview-footer {
  //   width:100%;
  //   // position: absolute;
  //   // bottom: 0;
  //   // left: 0;
  //   // right: 0;
  //   .estimate-note {
  //     padding: 15px 0;
  //     font-size: 1.4rem;
  //     line-height: 1.3;
  //     color: #767676;
  //     h3 {
  //       margin-bottom: 5px;
  //     }
  //   }
  //   .estimate-signature {
  //     font-size: 1.5rem;
  //     font-weight: 500;
  //   }

  // }
}

.sm-font-size {
  font-size: 12px;
}
