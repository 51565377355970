.mb-4{
  margin-bottom: 20px;
}

i{
  // color:#7D7D8D;
  font-weight: 200;
}

.pointer{
  cursor: pointer;
}

.danger-text{
  color:red;
}