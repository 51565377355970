@media print {
  .ant-layout-sider,
  .page-header {
    display: none;
  }

  .create-bills{
    display:none;
  }

  .ant-layout-sider {
    width: 0 !important;
  }

  .ant-layout.ant-layout-has-sider {
    margin-left: 0 !important;
  }

  .print-template-wrapper{
    padding:0px;
  }

  .download-page{
    display: block !important;
  }
  
  .app-page-body {
    padding: 0 !important;
    color:#000 !important;
   
  }

  .ant-layout-content.app-page-body {
    padding: 0 !important;
    // height: 1122px !important;
  }

  .ant-layout-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .estimate-detail-wrapper {
    box-shadow: none !important;
    padding: 0 !important;
    width:793.7px;
    height: 1122px !important;
    font-size: 10px !important;
  }

  .alt-pdf-header {
    .invoice-label-type {
      margin-bottom: 0 !important;
    }
    .company-info {
      .recipient-company-info {
        h3 {
          font-size: 13px;
        }
        p {
          line-height: 1.2;
          font-size: 11px;
        }
      }
    }

    .vat-no {
      margin-top: 0 !important;
      font-size: 11px;
      font-weight: semibold;
    }

    .estimate-time-info {
      p {
        font-size: 11px;
        line-height: 1.2;
      }
    }
  }

  .estimate-info--alternate {
    &.estimate-info {
      padding-bottom: 10px !important;
    }
    .billing-info {
      padding: 5px 10px !important;
      border-radius: 12px;
      line-height: 1.3;
      font-size: 12px;

      border: 3px solid #8292a7 !important;
    }
  }

  .estimate-detail {
    // background-color: red !important;
    .estimate-detail-header {
      margin-bottom: 0 !important;
      border-top: 1px solid #232323;
      border-bottom: 1px solid #232323;

      .recipient-company-info{
        h4{
          font-size: 10px;
        }
      }

      h3 {
        font-size: 12px !important;
      }
    }

    .estimate-detail-body {
      padding-block: 3px !important ;
      border-bottom: 1px solid #a8a8a8;

      .estimate-detail-item {
        font-size: 14px;
        h4,
        p {
          line-height: 1.2 !important;
        }
        h4 {
          font-size: 12px !important;
          width: 332px !important;
          margin-bottom: 0 !important;
          padding-bottom: 0 !important;
        }
        p {
          width: 332px !important;
          font-size: 11px !important;
          color: #000 !important;
        }
      }

      .ant-col {
        p {
          font-size: 11px !important;
        }
      }
    }

    .row-calculation {
      padding-block: 0px !important;
      line-height: 1.3 !important;

      p {
        font-size: 12px;
      }

      strong {
        font-size: 12px !important;
      }
    }

    .table-calculation{
      display: flex;
      flex-direction: column;
      padding-top:10px;
      .row-calculation{
        display: flex;
        justify-content: flex-end;
        align-items: center;
          &__title{
            margin-right:7px;
            
          }
          &__value{
            min-width:100px;
            text-align: right;
          }
  
          strong{
            font-weight: normal !important;
          }
       }
     }


    .estimate-total-amount {
      margin-top: 10px !important;
      .amount-words {
        p {
          line-height: 1.2;
          font-size: 12px;
        }
      }

      .total-amount {
        span {
          font-size: 13px;
        }
      }
    }
  }

  .estimate-note {
    padding-block: 5px !important;

    h3 {
      margin-bottom: 0 !important;
      font-size: 11px;
    }
    p {
      font-size: 11px;
    }
  }

  .preview-footer {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // padding-top: 20px;
    .estimate-footer {
      .estimate-signature {
        span {
          font-size: 12px;
          font-weight: bold;
        }

        .recipient-title {
          font-size: 12px;
        }
      }
    }
  }

  .printed-info-footer {
    margin-top: 0 !important;

    span {
      font-size: 11px;
    }
  }

  .customer-statement-print{
    display:block !important;
  }
  .row-customer-statement{
    page-break-inside:avoid !important; 
    // page-break-after:auto !important;
    
  }
  .breakafter{
    page-break-inside:avoid !important; 
    // page-break-before: always !important;
  }

  .template-80mm{

    .estimate-detail-body{
      .ant-col {
        p {
          font-size: 10px !important;
        }
      }

      .estimate-detail-item{
        h4{
          font-size:11px !important;
        }
      }
    
    }

    .table-calculation{
      border-top: 1px dashed $black !important;
      padding-bottom:10px;
      align-self: flex-end;
      min-width: 200px;
      .row-calculation{
        &__title{
            line-height: 17px;
          }
          &__value{
            line-height: 17px;
          }
       }
     }

     .header-80mm{
       &__company{
         color:#000 !important;
         h1{
         color:#000 !important;
          font-size:13px;
        }
        p{
          font-size: 11px;
        }
       }
     }
  }
}

@page {
  margin: 0;
  height: 1122px !important;
}

// .print-template{
//   padding:20px !important;
//   background-color: red !important;
//   // box-shadow: 6px 6px 42px rgba(0, 0, 0, 0.08);
//   border:1px solid #000;
// }

// .customer-statement-print{
//   display:none;
// }

.download-page{
  display: none;
}

.customer-statement-print{
  display:none;
}