.side-nav-error{
  background-color: #f4f6fb;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  padding:20px;
  height:100vh;
  position: fixed;
  position: fixed;
  left: 0;
  z-index: 999;
}


.container-error{
  display: flex;
  flex-direction: column;
   align-items: center;
   justify-content: center;
   width:100%;

  .illustration-img{
    height:300px;
  }

   button{
    margin-top:10px;
   }
}