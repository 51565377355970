// .button {
//   background-color: $primary-color;
//   height: 44px;
//   min-width: 150px;
//   padding: 0 30px;
//   font-size: 16px;
//   display: inline-flex;
//   justify-content: center;
//   align-items: center;
//   letter-spacing: .5px;
//   // text-transform: capitalize;
// }

.ant-btn.ant-btn-primary {
  font-size: 1.8rem;
  font-weight: 500;
  padding: 10px 35px;
  height: auto;
}
