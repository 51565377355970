@font-face {
  font-family: SFProDisplay;
  src: url("../../assets/Fonts/SFPro/SF-Pro-Display-Regular.otf")
    format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: SFProDisplay;
  src: url("../../assets/Fonts/SFPro/SF-Pro-Display-Bold.otf")
    format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: SFProDisplay;
  src: url("../../assets/Fonts/SFPro/SF-Pro-Display-Semibold.otf")
    format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: SFProDisplay;
  src: url("../../assets/Fonts/SFPro/SF-Pro-Display-Medium.otf")
    format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: SFProDisplay;
  src: url("../../assets/Fonts/SFPro/SF-Pro-Display-Light.otf")
    format("opentype");
  font-weight: 300;
}
@font-face {
  font-family: SFProDisplay;
  src: url("../../assets/Fonts/SFPro/SF-Pro-Display-Heavy.otf")
    format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: SFProDisplay;
  src: url("../../assets/Fonts/SFPro/SF-Pro-Display-Black.otf")
    format("opentype");
  font-weight: 900;
}
