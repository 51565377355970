.data-list-table {
  .ant-table-thead {
    tr {
      th {
        background: transparent;
        border-bottom: 1px solid #ccc;
        font-size: 16px;
        font-weight: 700;
        // padding-inline: 0;

        &:before {
          display: none;
        }

        &:not(:first-child) {
          text-align: center;
        }

        &.table-customer {
          text-align: left;
        }

        &.table-amount {
          text-align: right;
        }
      }
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        padding: 20px 10px;
        border: 0;
        &:not(:first-child) {
          text-align: center;
        }

        &.table-customer {
          text-align: left;
        }

        &.table-amount {
          text-align: right;
        }
      }
      &:nth-child(odd) {
        background: #f6fafe;
      }

      &:hover {
        background: #f6fafe;
      }

      .bill-thumb {
        height: 50px;
        width: 50px;
      }
    }
  }
  .action-btn-wrapper {
    display: flex;
    align-items: center;
    color: $primary;
    justify-content: center;
    cursor: pointer;
    .anticon {
      margin-left: 3px;
      background-color: white;
      border-radius: 100%;
      padding: 5px;
      border: 1px solid $primary;
    }
  }

  .sync-row {
    button {
      margin-left: 5px;
    }
    // background-color: red;
  }
}

.data-list-table--tds {
  .ant-table {
    .ant-table-thead {
      tr {
        th:last-child {
          text-align: center;
        }
      }
    }
    .ant-table-tbody {
      tr {
        td:last-child {
          text-align: center;
        }
      }

      .payment-status {
        border: 1px solid $primary;
      }
    }
  }
}

.anticon-delete,
.anticon-edit,
.anticon-dollar {
  color: lighten($primary, 18%) !important;
  font-size: 20px;
}

// .anticon-edit {
//   color: #909090 !important;
// }

.row--data-filter {
  margin-bottom: 30px;
}

.payment-status {
  // border: 2px solid $primary;
  display: inline-block;
  padding: 5px 7px 2px;
  min-width: 60px;
  text-align: center;
  border-radius: 3px;
  font-weight: 500;
  font-size: 13px;

  &.payment-full {
    background: lighten(map-get($colors, "green"), 60%);
    color: map-get($colors, "green");
  }
  &.payment-overdue {
    background: lighten(map-get($colors, "orange"), 40%);
    color: map-get($colors, "orange");
  }
  &.payment-partial {
    background: lighten(map-get($colors, "blue"), 45%);
    color: map-get($colors, "blue");
  }
  &.payment-unpaid {
    background: lighten(map-get($colors, "red"), 42%);
    color: map-get($colors, "red");
  }
}

.sync-row {
  button {
    border: 1px solid map-get($colors, "green");
    color: map-get($colors, "green");
  }
}

.ant-table-scroll-horizontal {
  .sync-row {
    text-align: center;
    button {
      margin-left: 0;
      margin-top: 3px;
    }
  }
}
