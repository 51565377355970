$primary: #256ff9;
$primary-light-background: #f1f7ff;
$text-primary: #101010;
$text-secondary: #767676;
$black:#000;

$red: #e42c2c;
$green: #00962b;
$red-light: #fdf3f3;
$green-light: #f1f8f1;
$blue-light: #f4f6fb;

$font-primary: "SFProDisplay";

$colors: (
  "primary": "$primary",
  "red": #e42c2c,
  "green": #00962b,
  "red-light": #fdf3f3,
  "green-light": #f1f8f1,
  "blue-light": #f4f6fb,
  "yellow": #e3c042,
  "blue": #1493e3,
  "orange": #fa9820,
);

@each $key, $val in $colors {
  .color-#{$key} {
    color: $val;
  }

  .bg-color-#{$key} {
    background-color: $val;
  }
}
