.login-container {
  height: 100vh;

  .ant-row {
    height: 100%;
  }
  .login-sidebar {
    height: 100%;
    width: 100%;
    position: relative;
    background: $primary;
    img {
      position: absolute;
      left: 0;
    }

    .sys-img {
      bottom: 0;
      width: 90%;
    }

    .dot-grid {
      top: 15px;
      left: 15px;
      width: 100px;
    }

    &__text {
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 7;
      text-align: center;
      max-width: 85%;
      width: 100%;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
      color: #fff;
      h2,
      p,
      h1,
      h3 {
        color: #fff;
      }
      h2 {
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.3;
        margin-bottom: 10px;
      }

      h1 {
        font-size: 4.4rem;
        font-weight: 700;
        text-align: left;
      }

      h3 {
        text-align: left;
        font-size: 2.6rem;
        font-weight: 500;
        margin-bottom: 10px;
      }

      ul {
        li {
          text-align: left;
          padding-block: 5px;
          // width: 80%;
          // margin-inline: auto;
          img {
            position: relative;
            margin-right: 10px;
          }
          span {
            display: inline-block;
          }
        }
      }
    }

    .login-page-text {
      top: 10%;
    }
  }

  .authentication-form-wrapper {
    display: grid;
    place-items: center;
    height: 100%;

    h2 {
      font-size: 4.8rem;
      font-weight: 700;
    }
    p {
      margin-bottom: 20px;
      color: #767676;
    }
    .authentication-form {
      max-width: 450px;
      border-radius: 8px;
      width: 100%;
      .ant-form {
        // .ant-form-item-control {
        //   width: 100%;
        // }
        .ant-form-item {
          margin-bottom: 7px;

          &.form-submit-btn {
            text-align: center;
            margin: 30px 0 10px;
            .ant-btn-primary {
              min-width: 180px;
              padding-inline: 30px;
              font-size: 1.6rem;
              height: auto;
              text-transform: uppercase;
              letter-spacing: 1.5px;
              font-weight: 700;
            }
          }
        }
        .ant-form-item-label {
          text-transform: capitalize;

          letter-spacing: 0.3px;
          .ant-form-item-required {
            &:before {
              position: absolute;
              right: -13px;
              top: 6px;
            }
          }
        }
        .privacy {
          margin-top: 10px;
          color: #878787;
        }
      }
      .register-link {
        text-align: center;
        font-size: 1.4rem;

        a {
          margin-left: 7px;
          font-weight: 700;
        }
      }
    }
  }
}
