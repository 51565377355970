.page-header {
  border-bottom: 1px solid #9eb7d7;
  .ant-page-header {
    &-heading {
      .ant-page-header-heading-title {
        font-size: 2.8rem;
      }
      .anticon {
        font-size: 20px;

        &.anticon-download {
          position: relative;
          top: 3px;
        }
      }

      .ant-space-item {
        div {
          .ant-btn {
            font-size: 1.5rem;
            padding: 7px 25px;
            letter-spacing: 0.5px;
            margin-left: 20px;

            // &:first-child {
            //   background: transparent;
            //   background-color: transparent;
            //   border: 1.5px solid $primary;
            //   color: $primary;
            // }
            &:last-child {
              // margin-left: 20px;
            }
            &:nth-child(even){
              background-color: transparent;
              border: 1.5px solid $primary;
              color: $primary;
            }
          }
        }
      }

      .ant-form {
        .ant-form-item {
          margin-bottom: 0;

          .ant-select {
            width: 120px;
          }
        }
      }
    }
  }

  &.header-sticky {
    position: sticky;
    top: 0;
    z-index: 999;
  }
}

.ant-page-header-heading-sub-title {
  margin-left: 30px;
  .ant-input-group-wrapper.ant-input-search {
    border: 1px solid #ccc;
    border-radius: 35px;
    padding-inline: 5px;
    .ant-input-affix-wrapper {
      border: 0;
      background: transparent;

      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
      }

      .ant-input {
        height: 35px;
        font-size: 1.6rem;
      }
    }
    .ant-input-group-addon {
      background: transparent;

      button {
        background: transparent;
        border: 0;
        padding-right: 5px;
      }
    }
  }
}

.popover-export-file {
  text-align: center;
}
