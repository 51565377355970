.data-filter-wrapper {
  // border: 3px solid #dae5f4;
  // padding: 15px;
  border-radius: 12px;
  .ant-form {
    .ant-form-item {
      margin-bottom: 0;
      &-control {
        .ant-picker {
          width: 100%;
        }

        .ant-input-group-addon {
          background: $primary-light-background;
          border: none;
        }

        .ant-input {
          border: 1px solid #dbdbdb;
        }
      }
    }
  }
}

.btn-filter-submit {
  cursor: pointer;
  p {
    border: 1.5px solid $primary;
    background: $primary-light-background;
    padding: 7px 12px;
    border-radius: 3px;
    color: $primary;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .anticon {
    font-size: 16px;
    margin-right: 5px;
    position: relative;
    top: 1px;
  }

  strong {
    text-transform: capitalize;
    font-weight: 500;
  }
}

.btn-clear-filter.ant-btn {
  border: 1px solid map-get($colors, "red");
  color: map-get($colors, "red");
  border-radius: 35px;
  height: 30px;
  padding: 3px 10px;
  margin-top: 15px;
  strong {
    font-weight: 500;
  }
}
