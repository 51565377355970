.sidebar {
  background-color: #f4f6fb;

  &__header {
    display: flex;
    padding: 15px 15px 10px;
    flex-direction: column;
    // background: #256ff9;
    border-bottom: 1.5px solid #7eaafb;
    margin-bottom: 10px;
    .avatar {
      margin-right: 15px;
      background: #505050;
    }

    h2 {
      font-size: 13px;
      color: #101010;
      margin-bottom: 0;
    }

    h3 {
      font-size: 12px;
      color: #909090;
      margin-bottom: 0;
      font-weight: 400;
      letter-spacing: 1px;
    }
  }

  .server-time {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: baseline;
    padding: 5px;
    font-size: 13px;
    margin-top: 10px;
    span {
    }

    strong {
      color: $primary;
      font-weight: 500;
      padding-left: 5px;
    }
  }

  .ant-menu {
    background-color: #f4f6fb !important;
  }

  .sub-menu {
    a {
      .badge {
      }
    }
  }

  &__secondary-menu {
    background-color: #f4f6fb;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #d0def0;

    a {
      padding: 6px 20px;
      color: #7d7d7d;
      span {
        padding-left: 5px;
        font-size: 16px;
      }
    }

    .app-version {
      padding: 10px 20px;
      color: #bfbfbf;
    }

    .logout{
      color:$primary
    }
  }
}
