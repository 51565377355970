.container-onboard {
  height: 100vh;

  .onboarding {
    height: 100%;

    .left-section {
      display: grid;
      place-content: center;
      padding-inline: 20px;
      text-align: center;
      height: 100%;
      background: #dae7fe;
      .onboarding-welcome {
        margin-top: 15px;
        h2 {
          font-size: 3.2rem;
          font-weight: 800;
        }

        p {
          font-size: 1.6rem;
          // color: $text-secondary;
        }
      }
    }

    .right-section {
      background-color: #f1f4f7;
      height: 100%;
      padding: 70px 70px 0;

      .steps-form-wrapper {
        max-width: 70rem;
        margin-inline: auto;

        .steps-content {
          margin-top: 4.5rem;
        }
        .steps-action {
          margin-top: 30px;
          text-align: center;

          .ant-btn {
            padding-inline: 60px;
          }
        }

        .form-submit-btn {
          margin-top: 50px;
          width: 100%;
          .ant-btn {
            letter-spacing: 1px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.onboarding-form-wrapper {
  form.form--onboarding {
    .ant-row {
      .ant-col {
        &.ant-form-item-label {
          label {
            text-transform: capitalize;
            font-weight: 600;
            font-size: 1.6rem;
          }
        }

        &.ant-form-item-control {
        }
      }
    }

    .form-inner-grid {
      h3 {
        font-weight: 600;
        margin-block: 10px;
      }

      .ant-col {
        &.ant-form-item-label {
          label {
            font-weight: 400;
            font-size: 1.4rem;
          }
        }

        .ant-form-item {
          margin-bottom: 10px;
        }
      }
    }
  }

  &.onboarding-form-wrapper--upload {
    .ant-form-item {
      align-items: center;
      text-align: center;

      label {
        font-size: 1.8rem;
        margin-bottom: 20px;
      }
    }
  }
}

.hidden {
  display: none;
}
