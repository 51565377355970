.pdf-hover {
  position: relative;
  display: inline-block;
  &::before {
    content: "";
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  .icon {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 2;
    font-size: 18px;
  }
  img {
    transition: all 0.3s ease-out;
  }
  &:hover {
    &::before {
      display: block;
    }
    .icon {
      display: block;
    }
  }
}
