.materialized-view {
  .search-container {
    .search-row {
      display: flex;
      align-items: center;
      background-color: #f1f7ff;
      padding: 10px 15px;

      .ant-col {
        // margin:5px;
      }
    }
  }

  // .initial-component {
  //   //  padding-top:40px;

  //   .initial-info {
  //     padding-top: 55px;

  //     img {
  //       width: 200px;
  //     }
  //   }
  // }
}