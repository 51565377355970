.create-estimate {
  .estimate-form {
    padding-block: 15px;
    max-width: 1100px;
    margin-inline: auto;

    .estimate-label-input-wrapper {
      padding-inline: 20px;

      .row--estimate-label {
        background: $primary-light-background;

        .ant-row {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .ant-form-item-control {
          width: 50%;
        }

        &.row--debit-label {
          .ant-row {
          }

          .ant-form-item-control {
            width: 100%;
          }
        }
      }
    }

    .estimate-information-wrapper {
      // background: #f6f6f6;
      box-shadow: 6px 6px 42px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      .information-section {

        .estimate-detail-header{
          padding-bottom:25px;
          margin-bottom:25px;
        }

        .add-customer-card {
          border-radius: 8px;
          .anticon {
            font-size: 64px;
            color: $primary;
          }

          h3 {
            font-size: 2.2rem;
            margin-left: 15px;
          }
        }

        .estimate-section {
          .ant-form-item {
            .ant-form-item-control {
              width: 270px;
              .ant-input-number,
              .ant-picker {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .information-section {
      flex: 1;
      background-color: #fff;
      padding: 20px;
      margin-top: 25px;

      .added-customer-detail {
        // cursor: pointer;

        .choose-customer-btn{
          padding:0px;
          font-weight: 600;
        }
      }

      .add-customer-card {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        border: 1px solid #cccccc;
        cursor: pointer;

        h3 {
          color: $primary;
        }
      }
    }

    .estimate-section {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .estimate-table-section {
      padding:7px 20px;

      .add-item-btn {
        color: $primary;
        padding: 20px;
        span {
          cursor: pointer;
        }
      }

      .ant-col {
        .ant-form-item {
          .ant-form-item-control-input {
            .ant-input-number {
              width: 100%;
            }
          }
        }
      }

      .btn-add-estimate-item {
        margin-top: 20px;
        color: $primary;
      }
    }

    .bottom-section {
      padding: 20px;
      flex: 1;

      .calculation-section {
        display: flex;
        flex-direction: column;

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }

        p {
          display: inline-block;
          strong {
            text-align: left;
            min-width: 130px;
            display: inline-block;
          }
        }

        span {
          width: 150px;
          text-align: right;
          display: inline-block;
        }
      }
    }

    .header-80mm{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      h1{
        font-size: 22px;
      }

      &__label{
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  .bottom-section {
    border-top: 1px solid #ccc;
    margin-top: 30px;

    .col-notes {
      .ant-form-item {
        display: flex;
        flex-direction: column;

        .ant-form-item-label {
          text-align: left;
        }
      }

      .tds-input {
        display: flex;
        align-items: center;
        margin: 0 0 10px;

        .ant-form-item {
          align-items: center;
          // margin-top:25px;
          margin: 0 10px;
        }
      }
    }

    .calculation-section {
      font-size: 1.6rem;

      div {
        padding-block: 3px;
      }

      strong {
        margin-right: 7px;
      }
      span {
        font-weight: 500;
      }
    }

    .row--final-price {
      margin-top: 15px;
      .amount-words,
      .final-estimate-price {
        padding: 5px 15px;
        background: $primary-light-background;
        font-size: 1.8rem;
      }
      .amount-words{
       text-transform: capitalize;
      }

      .final-estimate-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        strong {
        }
        span {
          color: $primary;
          font-weight: 700;
          text-align: right;
        }
      }
    }

    .upload-section {
      .btn {
        display: flex;
        cursor: pointer;
        .anticon {
          color: $primary;
          font-size: 24px;
        }
        h3 {
          color: $primary;
          margin-left: 5px;
        }
      }

      .ant-upload-select-picture-card {
        background-color: transparent;
        width: auto;
        height: auto;
        border-color: transparent;
      }
    }
  }

  .adjustment-input {
    display: flex;
    align-items: center;
    padding: 20px;
    border-top: 1px solid #ccc;
    .ant-form-item {
      margin: 0 10px;
    }
  }
  .payment-mode-input {
    padding: 20px;
    margin-bottom: 20px;
    // margin:8px 20px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    .ant-form-item {
      margin: 5px;
    }
  }
}

.estimate-table-header {
  background: $primary-light-background;
  padding: 5px 15px;
  border: 0;
  margin:0 !important;
  
  h3 {
    font-weight: 600;
  }

  .ant-col {
    &:nth-child(5) {
      h3 {
        text-align: right;
      }
    }
  }
}

.row-estimate-detail-item {
  padding-block: 15px;

  .estimate-item {
    .out-of-stock-warning{
     color:#F78357;
    }
  }
}

.row-estimate-final {
  align-items: center;

  // background-color: red;
  .estimate-tax {
    display: flex;
    align-items: center;
    margin-top: 10px;
    strong {
      margin-right: 15px;
    }
    .ant-form-item {
      flex-grow: 1;
      margin-bottom: 0;
    }
  }
}

.estimate-amount {
  text-align: right;
}

.remove-col {
  text-align: right;
  span {
    font-size: 22px;
    color: #cc3300;
  }
}

.row-estimate-item {
  padding-block: 20px;

  &:not(:first-child) {
    border-top: 1px solid #ccc;
  }
}

.upload-collapse {
  h3 {
    font-weight: 600;
    font-size: 16px;
    color: $primary;
  }
}


.create-bills-print{
    visibility: hidden;
    position: absolute;
    top:0px;
}