.ant-steps {
  .ant-steps-item {
    .ant-steps-item-container {
      .ant-steps-item-tail {
        &:after {
          height: 2.5px;
        }
      }
    }
  }
}
